.signup-modal__layout {
  // @extend .br-2;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    @extend .p-2;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $color--grey-light;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &__logo {
    margin: 16px 0;
    height: 40px;

    & .st0 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #51296a;
    }
  }

  &__title {
    @extend .px-2;
    text-align: center;
    font-family: $font--verlag;
    color: $color--purple;
    font-size: $text--5;
    font-weight: 300;
  }

  &__body {
    @extend .px-4, .pt-4;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #fff;
    flex: 1;
    overflow: auto;
  }

  &__button-bar {
    @extend .p-4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &__privacy-link {
    color: $color--purple;
    text-align: center;
    font-size: $text--2;
    text-decoration: underline;
    display: block;
  }

  &__guest-link {
    color: $color--purple;
    text-align: center;
    font-size: $text--2;
  }
}
