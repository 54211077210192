:root {
  scroll-behavior: smooth;
}

body {
  @extend .text-2, .text-s-3;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: $font--museo;
  color: $color--text-grey;
  line-height: 1.5;
}

iframe {
  max-width: 100%;
}

p {
  max-width: 43rem;
}

a {
  color: $color--blue;
  text-decoration: none;
}

a:active {
  color: #045387;
}

a:hover {
  text-decoration: underline;
}
