// Todo: abstract styles and potentially generalise class names once overlap becomes clear

.page {
  position: relative;
  overflow: hidden;
  padding-bottom: $space--6;
  // page is a direct child of body and fills any available space,
  // pushing the footer to the bottom of the viewport
  flex-grow: 1;

  &__member-content {
    padding-bottom: $space--0;
  }

  &__triangles {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    pointer-events: none;

    @add-mixin media s {
      display: block;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
    }

    &:before {
      top: -261px;
      right: -80px;
      border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) $color--orange-light;
      border-width: 0 0 885px 510px;
    }

    &:after {
      top: 100px;
      right: 0;
      border-width: 0 0 590px 340px;
      border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) $color--orange;
    }

    &--teal {
      @extend .page__triangles;
      &:before {
        border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #dceae6;
      }
      &:after {
        border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #88cbc0;
      }
    }

    &--blue {
      @extend .page__triangles;
      &:before {
        border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #d7e2ec;
      }
      &:after {
        border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) $color--blue-light;
      }
    }

    &--lower-triangles {
      @extend .page__triangles;
      z-index: -1;
      &:before {
        right: -138px;
        top: -18px;
      }
      &:after {
        top: 377px;
        right: 0px;
      }
    }
  }

  &--training-and-events-listing {
    background: $color--beige;
  }

  &--gated-content {
    background: linear-gradient(
      117deg,
      $color--blue 0%,
      $color--blue 16%,
      $color--purple 16%,
      $color--purple 67%,
      $color--blue-50 67%,
      $color--blue-50 100%
    );
  }

  &--news {
    // Todo: abstract
    &:before {
      content: '';
      display: none;
      position: absolute;
      top: -35px;
      left: -145px;
      width: 318px;
      height: 380px;
      background-color: $color--orange-light;
      transform: skew(-30deg);

      @add-mixin media m {
        display: block;
      }
    }

    &:after {
      content: '';
      display: none;
      position: absolute;
      top: -125px;
      left: -145px;
      width: 318px;
      height: 380px;
      background-color: $color--orange;
      transform: skew(-30deg);

      @add-mixin media m {
        display: block;
      }
    }
  }

  &--steps-index {
    background: $color--beige;

    &__content {
      max-width: $constrain--m;
      min-height: 60px;
      margin-top: calc($space--7 * -1);
      margin-left: auto;
      margin-right: auto;
    }
  }

  &--default {
    background: $color--beige;

    // Todo: abstract
    &:before {
      content: '';
      display: none;
      position: absolute;
      top: -35px;
      left: -125px;
      width: 318px;
      height: 380px;
      background-color: $color--teal;
      transform: skew(-30deg);

      @add-mixin media s {
        display: block;
      }
    }
  }
  &--campaign-index {
    background: $color--beige;

    &:before {
      content: '';
      display: none;
      position: absolute;
      top: -35px;
      left: -125px;
      width: 318px;
      height: 380px;
      background-color: $color--orange;
      transform: skew(-30deg);

      @add-mixin media s {
        display: block;
      }
    }
  }

  &--index,
  &--hg-index,
  &--person-index {
    background: $color--beige;

    &:before {
      content: '';
      display: none;
      position: absolute;
      top: -35px;
      left: -125px;
      width: 318px;
      height: 380px;
      background-color: $color--teal;
      transform: skew(-30deg);

      @add-mixin media s {
        display: block;
      }
    }
  }

  &--information {
    background-color: #fff;
  }

  // Todo: abstract
  &--publication-listing {
    background: $color--beige;
  }

  // provide additional spacing to avoid back to top collision with content
  &.back-to-top__parent {
    padding-bottom: $space--8;
  }
}
