.login-button {
  background-color: $color--purple;
  padding: $space--1;
  color: #fff;
  display: inline-block;
  text-decoration: underline;
  padding-left: 13px !important; // width of person.svg
  padding-left: calc(13px + $space--1) !important;
  background-image: url('./person.svg');
  background-position: left center;
  background-repeat: no-repeat;

  &:focus {
    border: 2px $color--teal solid;
  }
}
