.button {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: $border-radius--2;
  padding: $space--3 $space--4;
  font-size: $text--2;
  font-weight: 500;
  line-height: 1.375;
  transition: $transition--default;
  transition-property: color, background-color;

  // applicable for a.button
  text-transform: none;

  &:hover {
    text-decoration: none;
  }

  &__icon {
    flex-shrink: 0;
  }

  &--small {
    padding-top: $space--2;
    padding-bottom: $space--2;
  }

  &--blue {
    color: #fff;
    background-color: $color--blue;

    &:hover {
      color: #fff;
      background-color: #0565a5; /* $color-blue darken 10 */
    }

    &.button--alternate {
      background-color: #fff;
      color: $color--blue;
      box-shadow: 0 0 0 2px currentColor;

      &:hover {
        color: #fff;
        background-color: $color--blue;
      }
    }
  }

  &--icon-right {
    justify-content: center;
    width: 100%;
  }

  &--icon-right .button__icon {
    @extend .ml-4;
  }

  &--icon-left {
    justify-content: center;
    flex-direction: row-reverse;
    width: 100%;
  }

  &--icon-left .button__icon {
    @extend .mr-4;
    transform: scaleX(-1);
  }

  &--icon-only {
    @extend .p-0;
    background-color: transparent;
  }

  &[disabled] {
    cursor: default;
  }

  &[disabled],
  &--grey {
    color: #fff;
    background-color: $color--text-grey;

    &:hover {
      color: #fff;
      background-color: #2b2b2b; /* $color-text-grey darken 10 */
    }
  }

  &--teal {
    background-color: #88cbc0;
    color: $color--purple;

    &:hover {
      color: $color--purple;
      background-color: #70c0b3; /* #88cbc0 darken 10% */
    }
  }

  &--white {
    border: 1px solid $color--blue;
    background-color: #fff;
    color: $color--blue;

    &:hover {
      color: #fff;
      background-color: $color--blue;
    }
  }

  &--yellow {
    color: $color--purple;
    background-color: $color--yellow;
  }

  &--external,
  &--download {
    width: 100%;
    padding: $space--4;
    font-size: $text--4;
    font-weight: 900;
    // color: $color--purple;
    // background-color: $color--yellow;
  }

  &--download__size {
    display: block;
    font-weight: 500;
    font-size: $text--1;
    text-align: left;
  }
}
