@import 'w3';

.outer-footer {
  color: #fff;
  background-color: $color--purple;
  margin-top: auto;
  border-top: 4px solid #ffb496;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.1rem;
  clear: both;
  /* z-index to correctly overlay page triangles */
  z-index: 1;

  &__container {
    -webkit-flex-wrap: wrap; /* Safari 6.1+ */
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    justify-content: space-evenly;
  }

  &__component {
    padding-top: 3rem;
    padding-right: 1rem;
  }

  &__logo-link {
    display: block;
    background-image: url(images/NCVO-logo-with-strap-s-white.svg);
    background-repeat: no-repeat;
    background-size: 144px 174px;
    margin: -2rem 2rem 1rem 0;
    height: 174px;
    width: 144px;
  }

  &__more-from-logo {
    display: inline-block;
    background-size: cover;
    background-repeat: no-repeat;
    height: 16px;
    width: 16px;
    margin-right: 5px;
  }

  &__more-from-ncvogeneric {
    background-image: url(images/ncvogeneric.png);
  }

  &__more-from-ncvoknowhow {
    background-image: url(images/ncvoknowhow.png);
  }

  &__more-from-howcharitieswork {
    background-image: url(images/how-charities-work-favicon.png);
  }

  &__housekeeping-link,
  &__housekeeping-link:link,
  &__housekeeping-link:visited {
    color: #fff;
    text-decoration: none;
    display: inline-block;
    padding: 0 10px 12px 0;
  }

  &__housekeeping-link:hover,
  &__housekeeping-link:active {
    text-decoration: underline;
  }

  &__housekeeping {
    column-count: 2;
    max-width: 290px;
  }

  &__copyright-text {
    margin-top: 0;
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 1100px) {
  .outer-footer__container {
    flex-wrap: nowrap;
  }
}

@media print {
  .outer-footer {
    color: black;
    background-color: white;
    border-top: 1pt solid black;
  }
  .outer-footer__housekeeping,
  .outer-footer__more-from {
    display: none;
  }
}
