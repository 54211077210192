.max-width, /* deprecated class name */
.constrain-l {
  width: 100%;
  max-width: $constrain--l;
  margin-left: auto;
  margin-right: auto;
  padding-left: $gutter;
  padding-right: $gutter;

  // ensures content appears above any page background assets
  position: relative;
}

.constrain-m {
  width: 100%;
  max-width: $constrain--m;
  margin-left: auto;
  margin-right: auto;
  padding-left: $gutter;
  padding-right: $gutter;

  // ensures content appears above any page background assets
  position: relative;
}

.constrain-s {
  width: 100%;
  max-width: $constrain--s;
  margin-left: auto;
  margin-right: auto;
  padding-left: $gutter;
  padding-right: $gutter;

  // ensures content appears above any page background assets
  position: relative;
}

.no-gutter {
  margin-left: calc($gutter * -1);
  margin-right: calc($gutter * -1);
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto !important;
}

@each $width $i in $card-widths {
  // workaround not being able to start index count at 1
  @if $width != 0 {
    .card-width--$(i) {
      width: $width;
    }
  }
}

@each $height $i in $card-heights {
  // workaround not being able to start index count at 1
  @if $height != 0 {
    .card-height--$(i) {
      min-height: $height;
    }
  }
}

.card-width {
  @extend .card-width--1;
}

// ensures content appears above any page background assets
.position-relative {
  position: relative;
}

.flex {
  display: flex !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

// Todo: deprecate and move towards Bootstrap api https://github.com/twbs/bootstrap/blob/main/dist/css/bootstrap-grid.css
.d-flex {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &-row {
    @extend .d-flex;
    flex-direction: row !important;
  }

  &-center-x {
    @extend .d-flex;
    align-items: center;
  }

  &-center-y {
    @extend .d-flex;
    justify-content: center;
  }

  &-center-xy {
    @extend .d-flex-center-x, .d-flex-center-y;
  }

  &-end {
    @extend .d-flex;
    justify-content: flex-end;
  }

  &-align-end {
    @extend .d-flex;
    align-items: flex-end;
  }
}

.focus-visible-styles:focus {
  border: 2px $color--teal solid;
}

.box-shadow {
  box-shadow: 0px 0px 5px $color--grey;
}

.d-inline-block {
  display: inline-block !important;
}

.push-right {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

// [start] margin helpers

.mt-auto {
  margin-top: auto;
}

.mr-auto {
  margin-right: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-auto {
  margin-left: auto;
}

@each $space $i in $spaces {
  .-m-$(i) {
    margin: calc($space * -1);
  }

  .m-$(i) {
    margin: $space;
  }

  .mt-$(i) {
    margin-top: $space;
  }

  .mr-$(i) {
    margin-right: $space;
  }

  .mb-$(i) {
    margin-bottom: $space;
  }

  .ml-$(i) {
    margin-left: $space;
  }

  .mx-$(i) {
    margin-left: $space;
    margin-right: $space;
  }

  .-mx-$(i) {
    margin-left: calc($space * -1);
    margin-right: calc($space * -1);
  }

  .my-$(i) {
    margin-top: $space;
    margin-bottom: $space;
  }

  .-my-$(i) {
    margin-top: calc($space * -1);
    margin-bottom: calc($space * -1);
  }
}

@each $breakpoint in $breakpoints {
  @each $space $i in $spaces {
    .m-$(breakpoint)-$(i) {
      @add-mixin media $breakpoint {
        margin: $space;
      }
    }

    .mt-$(breakpoint)-$(i) {
      @add-mixin media $breakpoint {
        margin-top: $space;
      }
    }

    .mr-$(breakpoint)-$(i) {
      @add-mixin media $breakpoint {
        margin-right: $space;
      }
    }

    .mb-$(breakpoint)-$(i) {
      @add-mixin media $breakpoint {
        margin-bottom: $space;
      }
    }

    .ml-$(breakpoint)-$(i) {
      @add-mixin media $breakpoint {
        margin-left: $space;
      }
    }

    .mx-$(breakpoint)-$(i) {
      @add-mixin media $breakpoint {
        margin-left: $space;
        margin-right: $space;
      }
    }

    .my-$(breakpoint)-$(i) {
      @add-mixin media $breakpoint {
        margin-top: $space;
        margin-bottom: $space;
      }
    }
  }
}

// [end] margin helpers

// [start] padding helpers

@each $space $i in $spaces {
  .p-$(i) {
    padding: $space;
  }

  .pt-$(i) {
    padding-top: $space;
  }

  .pr-$(i) {
    padding-right: $space;
  }

  .pb-$(i) {
    padding-bottom: $space;
  }

  .pl-$(i) {
    padding-left: $space;
  }

  .px-$(i) {
    padding-left: $space;
    padding-right: $space;
  }

  .py-$(i) {
    padding-top: $space;
    padding-bottom: $space;
  }
}

@each $breakpoint in $breakpoints {
  @each $space $i in $spaces {
    .p-$(breakpoint)-$(i) {
      @add-mixin media $breakpoint {
        padding: $space;
      }
    }

    .pt-$(breakpoint)-$(i) {
      @add-mixin media $breakpoint {
        padding-top: $space;
      }
    }

    .pr-$(breakpoint)-$(i) {
      @add-mixin media $breakpoint {
        padding-right: $space;
      }
    }

    .pb-$(breakpoint)-$(i) {
      @add-mixin media $breakpoint {
        padding-bottom: $space;
      }
    }

    .pl-$(breakpoint)-$(i) {
      @add-mixin media $breakpoint {
        padding-left: $space;
      }
    }

    .px-$(breakpoint)-$(i) {
      @add-mixin media $breakpoint {
        padding-left: $space;
        padding-right: $space;
      }
    }

    .py-$(breakpoint)-$(i) {
      @add-mixin media $breakpoint {
        padding-top: $space;
        padding-bottom: $space;
      }
    }
  }
}

// [end] padding helpers

// [start] border radius helpers

@each $border-radius $i in $border-radius-scale {
  .br-$(i) {
    border-radius: $border-radius;
  }
}
// [end] border radius helpers

// [start] color helpers

// Todo: ideally we'd loop key, value pairs from _variables here

.c-teal {
  color: #89cbc0 !important;
}
.bg-teal {
  background-color: #89cbc0;
}

.c-teal-light {
  color: #a0d4cc !important;
}
.bg-teal-light {
  background-color: #a0d4cc;
}

.c-teal-highlight {
  color: #e4f6f2 !important;
}
.bg-teal-highlight {
  background-color: #e4f6f2;
}

.c-teal-dark {
  color: #34a492 !important;
}
.bg-teal-dark {
  background-color: #34a492;
}

.c-teal-darker {
  color: #268375 !important;
}
.bg-teal-darker {
  background-color: #268375;
}

.c-beige {
  color: #f1f2f0 !important;
}
.bg-beige {
  background-color: #f1f2f0;
}

.c-sand {
  color: #f4f2f1 !important;
}
.bg-sand {
  background-color: #f4f2f1;
}

.c-purple {
  color: #51296a !important;
}
.bg-purple {
  background-color: #51296a;
}

.c-purple-lighter {
  color: #633f79 !important;
}
.bg-purple-lighter {
  background-color: #633f79;
}

.c-text-grey {
  color: #444 !important;
}
.bg-text-grey {
  background-color: #444;
}

.c-grey {
  color: #b0b0b0 !important;
}
.bg-grey {
  background-color: #b0b0b0;
}

.c-grey-light {
  color: #f5f5f4 !important;
}
.bg-grey-light {
  background-color: #f5f5f4;
}

.c-yellow {
  color: #ffef42 !important;
}
.bg-yellow {
  background-color: #ffef42;
}

.c-blue {
  color: #0671b8 !important;
}
.bg-blue {
  background-color: #0671b8;
}

.c-blue-light {
  color: #a7d1ff !important; // this colour is designed to be accessible on purple background
}
.bg-blue-light {
  background-color: #a7d1ff;
}

.c-white {
  color: #fff !important;
}
.bg-white {
  background-color: #fff;
}

.c-orange {
  color: #ffb395 !important;
}
.bg-orange {
  background-color: #ffb395;
}

.c-orange-light {
  color: #f6e5de !important;
}
.bg-orange-light {
  background-color: #f6e5de;
}

.c-orange-dark {
  color: #d95926 !important;
}
.bg-orange-dark {
  background-color: #d95926;
}

.bg-transparent-overlay {
  background-color: rgba(255, 255, 255, 0.9);
}

// [end] color helpers

// [start] type scale helpers

@each $size $i in $type-scale {
  // workaround not being able to start index count at 1
  @if $size != 0 {
    .text-$(i) {
      font-size: $size;
    }

    @each $breakpoint in $breakpoints {
      .text-$(breakpoint)-$(i) {
        @add-mixin media $breakpoint {
          font-size: $size;
        }
      }
    }
  }
}

// [end] type scale helpers

// [start] font weight helpers

@each $font-weight $i in $font-weight-scale {
  .fw-$(i) {
    font-weight: $font-weight;
  }
}
// [end] font weight helpers

// Text utility classes

.center-text {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

// [start] visibility helpers

.hide {
  display: none !important;
}

@each $breakpoint in $breakpoints {
  .hide-$(breakpoint) {
    @add-mixin media $breakpoint {
      display: none !important;
    }
  }

  .show-$(breakpoint) {
    @add-mixin media $breakpoint {
      display: block !important;
    }
  }
}

// [end] visibility helpers

// deprecated
// Target IE11
@define-mixin ie11() {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @mixin-content;
  }
}

// Visually hidden
@define-mixin sr-only() {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.visually-hidden {
  @add-mixin sr-only();
}

// deprecrated for .hide, .hide-<breakpoint>
.u {
  &-desktop-only-ib {
    display: none;

    @add-mixin media s {
      display: inline-block;
    }
  }

  &-desktop-only,
  &-desktop-only-b {
    display: none;

    @add-mixin media s {
      display: block;
    }
  }

  &-mobile-only {
    @add-mixin media s {
      display: none;
    }
  }
}

.responsive-video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.responsive-video:has(iframe:not([src])) {
  display: none;
}
