/**
 * HACK: Copied from redesign/button to reduce friction between this branch and the refactored core components
 * TODO: use @extend of the core components in the .ch2-btn and .ch2-btn-secondary
 **/
.cookiehub-button {
  display: inline-block;
  border-radius: $border-radius--2;
  padding: 13px 20px 10px;
  color: #fff;
  font-size: 17px;
  line-height: 22px;
  cursor: pointer;
  transition: background-color $transition--default, color $transition--default;

  // Overrides
  margin: 0;
  text-transform: none;
  font-weight: 500;

  // Variants
  &--blue {
    background-color: $color--blue;

    &:hover {
      text-decoration: none;
      color: #fff;
      background-color: #0565a5; // darkened $color-blue 10%
    }

    &:visited {
      color: #fff;
    }
  }

  &--teal {
    background-color: #88cbc0;
    color: $color--purple;

    &:hover {
      text-decoration: none;
      color: $color--purple;
      background-color: #70c0b3; // darkened 88cbc0 by 10%
    }

    &:visited {
      color: $color--purple;
    }
  }
}

/**
 * Cookiehub overrides
 **/
body .ch2 {
  .ch2-container {
    p {
      font-size: 20px;
      line-height: 34px;
      color: $color--purple;
    }

    /* needs to be more specific */
    .ch2-dialog-content #ch2-dialog-title strong {
      color: $color--purple;
      font-family: $font--verlag;
      font-size: 40;
    }
  }

  .ch2-style-light .ch2-switch input:checked + label,
  .ch2-style-dark .ch2-switch input:checked + label {
    background-color: #0671b8;
  }

  .ch2-container,
  .ch2-icon {
    font-family: inherit;
  }

  .ch2-btn {
    @extend .cookiehub-button--blue;
  }

  .ch2-btn-secondary {
    @extend .cookiehub-button--teal;
  }

  .ch2-container a {
    color: #0671b8;
    text-decoration: none;
    border-bottom: none;
    font-size: 17px;

    &:hover,
    &:focus {
      border-bottom: none;
      color: #0671b8;
      /* the styles from cookiehub include !important... */
      text-decoration: underline !important;
    }
  }
}
