.outer-header {
  @extend .py-1;
  width: 100%;
  background: $color--purple;
  min-height: 3.5rem;
  /* z-index to correctly overlay page triangles */
  z-index: 20;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__links {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__item + &__item {
    margin-left: $space--6;
  }

  &__link {
    padding: $space--1;
    color: #fff;
    white-space: nowrap;
  }

  &__ncvo-logo {
    padding: 0 1rem;
  }

  &__ncvo-logo-link {
    display: block;
    width: 91px;
    height: 44px;
    background-image: url(images/ncvo_logo_white.svg);
    background-size: 91px 44px;
    background-position: 0 0;
    background-repeat: no-repeat;
  }
}

.skip-link {
  position: fixed;
  top: -200px;

  &:focus {
    top: 0;
  }
}

@media print {
  .outer-header,
  .skip-link {
    display: none;
  }
}
