@import '../heading/heading';

.rich-text {
  & + & {
    margin-top: $space--7;
  }

  > * + * {
    margin-top: 1.5em;
  }

  > p {
    font-family: $font--museo;
    > a {
      text-decoration: underline;
      color: $color--blue;
    }
  }

  > p,
  > ul,
  > ol,
  > h2,
  > h3,
  > h4 {
    max-width: 43rem;
  }

  ol {
    counter-reset: ol-counter;
  }

  ul li {
    position: relative;
    padding-left: $space--6;

    &::before {
      content: '';
      position: absolute;
      left: $space--3;
      top: 0.5em;
      width: 7px;
      height: 7px;
      margin: 0;
      background-size: cover;
      background-color: $color--purple;
    }

    ul,
    ol {
      margin-top: 1em;
    }
  }

  ol li {
    padding-left: $space--6;

    &::before {
      content: counter(ol-counter);
      counter-increment: ol-counter;
      display: inline-block;
      font-weight: 700;
      font-family: $font--museo;
      color: $color--purple;
      margin-left: -25px;
      width: 25px;
    }

    ul,
    ol {
      margin-top: 1em;
    }
  }

  li + li,
  li + li {
    margin-top: 0.5em;
  }

  > h2 {
    color: $color--purple;
    font-family: $font--museo;
    font-weight: 700;
    font-size: $text--6;
  }

  > h3 {
    font-size: $text--4;
    color: $color--purple;
  }

  > h4 {
    font-size: $text--3;
    color: $color--purple;
  }

  &--icon-numbers ol {
    list-style: none;
    counter-reset: item;
    margin-top: 4rem;
    padding-left: 0;

    li {
      position: relative;
      counter-increment: item;
      padding-left: 0;

      &::before {
        content: counter(item);
        position: absolute;

        font-weight: 700;
        color: $color--purple;

        line-height: 1;
        margin-left: 0;
        transform: translateY(-50%);
        background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='30'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Crect fill='%23FFB395' y='14' width='50' height='4' rx='2'%3E%3C/rect%3E%3Cg transform='translate(9.5)'%3E%3Ccircle fill='%23FFB395' cx='15' cy='15' r='15'%3E%3C/circle%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-size: 100%;
        background-repeat: no-repeat;
        width: 50px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -$space--4;
      }
    }

    li + li,
    li + li {
      margin-top: 0;
    }
  }

  &--alternative-numbers ol {
    list-style: none;
    counter-reset: item;

    li {
      position: relative;
      counter-increment: item;

      // number
      &::before {
        content: counter(item);
        position: absolute;
        top: 25%;
        top: calc(50% - 8px);
        left: 5px;
        font-weight: 700;
        color: $color--purple;
        display: inline-block;
        line-height: 1;
        margin-left: 0;
        transform: translateY(-50%);
      }

      // line
      &::after {
        content: '';
        position: absolute;
        left: 13px;
        top: 50%;
        width: 12px;
        height: 2px;
        border-radius: $border-radius--1;
        background-color: $color--purple;
        transform: rotate(-58deg);
      }
    }
  }

  &--large-numbers ol li {
    padding-left: $space--6;

    // number
    &::before {
      font-size: $text--5;
    }

    // line
    &::after {
      width: 18px;
    }
  }

  .page--campaign-landing & {
    h3 {
      font-size: $text--7;
      font-family: $font--verlag;
      font-weight: 900;
      line-height: 1.09;
      margin-bottom: $space--5;
      opacity: 1;
      color: rgba(255, 255, 255, 1);
      font-style: normal;
      letter-spacing: 0px;
      text-align: left;
      margin-top: 0;

      @add-mixin media s {
        font-size: $text--9;
      }
    }

    p {
      font-weight: 500;
      line-height: 1.7;
      margin-bottom: 30px;
      max-width: 780px;
    }

    ul li:before {
      background-color: $color--orange;
    }

    li {
      font-weight: 500;
    }

    @add-mixin media s {
      p,
      li {
        font-size: $text--4;
      }
    }
  }

  // Todo: avoid page-specific variants by moving to themable components
  .page--tools & {
    ul li:before {
      background-color: $color--yellow;
    }
  }

  // Todo: avoid page-specific variants by moving to themable components
  .page--news &,
  .page--news-index &,
  .page--news-insights &,
  [class*='page--publication'] & {
    ul li:before {
      background-color: $color--orange;
    }
  }

  // Todo: avoid page-specific variants by moving to themable components
  .page--hg-index &,
  .page--hg-guidance & {
    ul li:before {
      background-color: $color--teal;
    }
  }
}
