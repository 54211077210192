.w3-button {
  border: none;
  display: inline-block;
  padding: 8px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  font-family: $font--museo;
  margin: 0;
  font-size: 1em;
  text-transform: none;
  border-radius: 0;
  font-weight: 300;
  -webkit-touch-callout: none;
  user-select: none;
}

.w3-dropdown-click {
  position: relative;
  display: inline-block;
  cursor: pointer;

  &:hover {
    background-color: #ccc;
    color: #000;
  }

  &:hover > .w3-button {
    background-color: #ccc;
    color: #000;
  }
}

.w3-show {
  display: block !important;
}

.w3-dropdown-content {
  cursor: auto;
  color: #000;
  background-color: #fff;
  display: none;
  position: absolute;
  bottom: 33px;
  min-width: 160px;
  margin: 0;
  padding: 0;
  z-index: 1;
}

.w3-bar-item {
  width: auto;
  display: block;
  padding: 8px 16px;
  text-align: left;
  border: none;
  white-space: normal;
  float: none;
  outline: 0;
}

.w3-container:after,
.w3-container:before {
  content: '';
  display: table;
  clear: both;
}

.w3-border {
  border: 1px solid #ccc !important;
}

.w3-button:hover {
  color: #000 !important;
  background-color: #ccc !important;
}

.w3-black {
  color: #fff !important;
  background-color: #000 !important;
}
.social {
  margin: 5px;
}
.paddinggap {
  margin-top: 5px;
  // padding-top: 5px;
}

.social-text {
  color: rgb(243, 236, 236);
  padding-left: 22px;
}

.email-link {
  background-image: url(images/icons8-mail.svg);
  background-repeat: no-repeat;
}
.x-link {
  background-image: url(images/x-logo.svg);
  background-repeat: no-repeat;
}
.linkedin-link {
  background-image: url(images/icons8-linkedin.svg);
  background-repeat: no-repeat;
}
.instagram-link {
  background-image: url(images/instagram-logo.svg);
  background-repeat: no-repeat;
}
.youtube-link {
  background-image: url(images/icons8-youtube.svg);
  background-repeat: no-repeat;
}
.slideshare-link {
  background-image: url(images/icons8-slideshare.svg);
  background-repeat: no-repeat;
}
