// applied as a grid track instead of grid-gap to allow for IE11 support
$grid-gap: $space--7;

.layout-default {
  display: flex;
  flex-direction: column;
  max-width: $constrain--m;
  padding: 0 $gutter;
  margin: 0 auto;

  &__left {
    display: flex;
    flex-direction: column;
    -ms-grid-column: 1;
    grid-column: 1;
    margin-top: $space--5;

    @add-mixin media l {
      margin-top: 0;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    -ms-grid-column: 5;
    grid-column: 5;
    margin-top: $space--5;

    @add-mixin media l {
      margin-top: 0;
    }
  }

  &__content {
    -ms-grid-column: 3;
    grid-column: 3;

    // have content appear before left and right columns on small viewports
    order: -1;

    // to ensure the content is positioned above the the triangles
    position: relative;

    display: flex;
    flex-direction: column;
  }

  // children often have collapsing y margins, here we remove the trailing margin
  &__right > *:last-child,
  &__content > *:last-child {
    margin-bottom: 0 !important;
  }

  &--wide-right &__content {
    -ms-grid-column: 3;
    -ms-grid-column-span: 3;
    grid-column: 3 / 6;
    order: 1;
  }

  &--mobile-menu &__content {
    order: 0;
  }

  @add-mixin media l {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 215px 60px minmax(0, 1fr) 60px 215px;
    grid-template-columns: 215px 60px minmax(0, 1fr) 60px 215px;
    max-width: $constrain--l;

    &__content {
      // reset order for grid layout to avoid creating multiple rows
      order: initial;
    }
  }
}

.aside {
  background-color: transparent;
  box-sizing: border-box;
  border-top: 0;
  padding: 0;
  margin: 0;
  max-width: 220px;

  &__heading {
    font-size: 21px;
    font-weight: 700;
    padding: 0;
    margin: 20px 0 25px;
    background-color: transparent;
    color: $color--purple;
  }
  &__list {
    margin: 0;
    padding: 0;
  }

  &__list-item {
    margin: 0 0 25px;
    font-size: 14px;

    > a {
      color: $color--blue;
    }

    &--active {
      > a {
        color: $color--text-grey;
      }
    }
  }
}

$grid-gap: $space--7;

.infolayout-default {
  display: flex;
  flex-direction: column;
  max-width: $constrain--m;
  padding: 0 $gutter;
  margin: 0 auto;
  padding-top: 2em;

  &__left {
    display: flex;
    flex-direction: column;
    -ms-grid-column: 1;
    grid-column: 1;
    margin-top: $space--5;
    grid-row: 1;

    @add-mixin media l {
      margin-top: 0;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    -ms-grid-column: 5;
    grid-column: 5;
    margin-top: $space--5;
    padding-top: 1.3em;

    @add-mixin media l {
      margin-top: 0;
    }
  }

  &__content {
    -ms-grid-column: 3;
    grid-column: 3;

    // have content appear before left and right columns on small viewports
    order: -1;

    // to ensure the content is positioned above the the triangles
    position: relative;

    display: flex;
    flex-direction: column;
  }

  // children often have collapsing y margins, here we remove the trailing margin
  &__right > *:last-child,
  &__content > *:last-child {
    margin-bottom: 0 !important;
  }

  &--wide-right &__content {
    -ms-grid-column: 3;
    -ms-grid-column-span: 3;
    grid-column: 3 / 6;
    order: 1;
  }

  &--mobile-menu &__content {
    order: 0;
  }

  @add-mixin media l {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 215px 60px minmax(0, 1fr) 60px 215px;
    grid-template-columns: 215px 60px minmax(0, 1fr) 60px 215px;
    max-width: $constrain--l;

    &__content {
      // reset order for grid layout to avoid creating multiple rows
      order: initial;
    }
  }
}
