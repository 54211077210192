.checkbox {
  display: flex;
  flex-direction: row-reverse;
  align-items: top;
  justify-content: flex-end;

  &__custom-checkbox {
    @extend .mr-2;
    width: $text--4;
    height: $text--4;
    background-image: url(images/unchecked.svg);
    background-size: cover;
    background-repeat: no-repeat;
    flex-shrink: 0;
    border-radius: $border-radius--1;
    border: 2px transparent solid;

    &--medium-size {
      width: $space--6;
      height: $space--6;
      background-size: 100%;
    }
  }

  &__input {
    @extend .visually-hidden;
  }

  &__input:checked + &__custom-checkbox {
    background-image: url(images/checked.svg);
  }

  &__input:focus + &__custom-checkbox {
    border: 2px $color--teal solid;
  }

  &__text {
    background-color: transparent;
    font-family: $font--museo;
    font-weight: normal;
    font-size: $text--1;
    color: $color--text-grey;

    &--purple {
      font-family: $font--museo;
      font-weight: 600;
      font-size: $text--1;
      color: $color--purple;
    }

    &--medium-white-text {
      font-family: $font--museo;
      font-weight: 500;
      font-size: $text--3;
      color: $color--white;
    }

    &--required {
      color: $color--yellow;
      font-size: $text--1;
      margin-top: -$space--1;
    }
  }
}
