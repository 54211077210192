@import '../core-components/heading/heading';
@import '../core-components/layout/layout-default/layout-default';

.forbidden-index-cards-container {
  display: flex;
  width: 100%;
}

.forbidden-card-text {
  font-weight: 600;
  line-height: 2;
}

.forbidden-spacing {
  margin: 0 $space--5;
}

.forbidden-container {
  min-width: 800px;
}

.forbidden-index-card {
  display: flex;
  height: 100%;
  font-size: $text--3;
  color: $color--purple;

  &:not(.index-card--split):hover {
    text-decoration: none;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: $space--5;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__icon {
    flex-shrink: 0;
    align-self: flex-end;
    width: 24px;
    height: 24px;
    margin-left: $space--2;
    color: $color--purple;
    transition: transform $transition--default;
  }

  &:hover &__icon {
    transform: translateX(3px);
  }

  &__link {
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }
}
