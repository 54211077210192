.heading {
  &--important {
    @extend .text-9, .text-s-8;

    font-family: $font--verlag;
    color: $color--purple;
    text-transform: uppercase;
    font-weight: 900;
    line-height: 1.25;
  }

  &--important-smaller {
    @extend .heading--important;

    font-size: $text--7;
  }

  &--primary {
    color: $color--purple;
    text-transform: none;
    font-size: $text--7;
    font-weight: 900;

    &--home {
      font-size: $text--8;
      font-family: $font--verlag;

      @add-mixin media s {
        font-size: $text--11;
      }
    }
  }

  &--secondary-plain {
    position: relative;
    padding-top: $space--3;
    padding-left: 35px;
    margin-left: -25px;
    color: $color--purple;
    font-weight: 700;
    font-size: $text--6;

    @add-mixin media s {
      margin-left: -35px;
    }
  }

  &--secondary {
    @extend .heading--secondary-plain;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 40px;
      height: 4px;
      top: 19px;
      border-radius: $border-radius--2;
      background-color: currentColor;
      transform: rotate(-58deg);
    }
  }

  &--tertiary {
    color: $color--purple;
    font-size: $text--3;
    font-weight: 700;
  }

  &--page {
    margin-bottom: $space--4;
    font-size: $text--8;
    font-weight: 900;
    color: $color--purple;
    line-height: 1.25;
  }

  &--white {
    color: $color--white;
    text-transform: none;
    font-size: $text--8;
    font-weight: 900;
  }

  &--white-wrap {
    color: $color--white;
    text-transform: none;
    font-size: $text--9;
    line-height: 1.4;
    font-weight: 900;
    max-width: 22ch;
  }

  &--small {
    color: $color--purple;
    font-size: $text--3;
    font-weight: 900;
  }

  &--grouped {
    color: $color--purple;
    font-size: $text--5;
    font-weight: 900;
    line-height: 1.25;
  }
}
