@import './components/Layout';
@import '../text-input/text-input';
@import '../checkbox/checkbox';
@import '../button/button';
@import '../grid/grid';
@import '../stack/stack';
@import '../typeahead/typeahead';
@import '../icon-message/icon-message';
@import '../link/link';

.signup-modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;

  &__overlay {
    background-color: rgba(81, 41, 106, 0.8);
    position: absolute;
    height: 100%;
    width: 100%;
  }

  &__overflow-wrapper {
    position: relative;
    overflow: auto;
    display: flex;
    height: 100%;
    width: 100%;
  }

  &__layout-wrapper {
    width: 350px;
    height: 620px;
    margin: auto;
  }
}
.signup-non-modal-container {
  display: flex;
  background-color: $color--purple;
  padding: $space--7 0;
  height: 100%;
}
