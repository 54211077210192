@import '../core-components/heading/heading';
@import '../core-components/layout/layout-default/layout-default';

.unauthorized-link {
  font-family: $font--verlag;
  font-size: $text--6;
  margin: $space--6 0;
  color: $color--purple;
  font-weight: 300;

  & a {
    padding: $space--1 0;
    border-bottom: solid 2px $color--blue;

    &:hover {
      text-decoration: none;
    }
  }
}

.unauthorized-message {
  width: 100%;
  max-width: 500px;
  margin-right: $space--4;
}

.unauthorized-wrapper {
  display: flex;
}

.unauthorized-image {
  max-width: 250px;
}
