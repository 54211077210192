.message {
  padding: $space--3;
  background-color: #f6f6f6;
  border-radius: $border-radius--2;
  border: 1px solid #d4d5cf;
  margin: $space--3 0;
  overflow: auto;

  &--warning {
    background-color: #f6f1b6;
    border: 1px solid #ece260;

    .message__close .fa-times {
      color: #6e690c;

      &:hover {
        color: #b8af14;
      }
    }
  }

  &--info {
    background-color: #d8f3e5;
    border: 1px solid #aae7c5;
  }

  &__close {
    float: right;
    margin: -0.5rem 0 0 1rem;
    color: #1f6b42;
    padding: 0;
    min-width: 0;
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }

    .fa-times {
      font-size: $text--3;
      color: #1f6b42;

      &:hover {
        color: #35bb78;
      }
    }
  }

  &__icon {
    display: block;
    float: left;
    width: 4rem;
    color: $color--purple;
    margin-right: -4rem;

    .fas {
      font-size: 3rem;
      color: $color--purple;
      vertical-align: top;

      &:hover {
        color: $color--purple;
        cursor: pointer;
        cursor: initial;
      }
    }
  }

  &__text {
    margin-left: $space--7;
  }

  &__close + .message__text,
  &__text:first-child {
    margin-left: 0;
  }
}
