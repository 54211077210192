@import '../text-input/text-input';
@import '../link/link';
@import '../../../node_modules/react-bootstrap-typeahead/css/Typeahead.min.css';

@keyframes shimmer {
  0% {
    background-position: -300% 0;
  }
  100% {
    background-position: 500% 0;
  }
}

.typeahead {
  @extend .text-input;

  &__input,
  & .rbt-input-main,
  & .rbt-input-hint {
    @extend .text-input__input;
  }

  &__hint {
    @extend .text-input__hint;
  }

  .typeahead__component--open &__input,
  & .typeahead__component--open .rbt-input-main,
  & .typeahead__component--open .rbt-input-hint {
    @extend .text-input__input:focus;

    border-radius: $border-radius--2 $border-radius--2 0 0;
  }

  &__placeholder-hint {
    height: auto !important; // bootstrap typeahead attaches some inline styles
  }

  .dropdown-item {
    display: block;
    padding: $space--2;
    text-align: center;
    color: $color--text-grey;
    font-size: 1rem;
    font-weight: normal;

    &:nth-child(even) {
      background-color: $color--grey-light;
    }

    &.active,
    &:not(.disabled):hover {
      color: #fff;
      background-color: $color--blue;
    }
  }

  .dropdown-item__loader {
    height: 15px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    background-size: 75% 100%;
    animation-duration: 1s;
    animation-name: shimmer;
    animation-iteration-count: infinite;
    background-repeat: no-repeat;
    animation-timing-function: ease;
    background-position: 0 0;
    background-color: $color--grey;
    background-blend-mode: overlay;
    border-radius: 10px;
  }

  &__menu-overlay {
    display: flex;
    flex-direction: column;
    position: relative;
    // the margin underneath an input
    // -
    // one border
    margin-top: calc(-$space--1 - 2px);

    border: 2px solid $color--teal;
    border-radius: 0 0 $border-radius--2 $border-radius--2;
  }

  &__menu-wrapper {
    background: #fff;
    max-height: 225px;
    overflow-y: auto;
  }

  .rbt-menu {
    max-height: none !important;
    overflow: visible !important;
  }

  &__menu-hint {
    @extend .py-2, .pb-1;
    @extend .text-1;

    color: $color--text-grey;
    border-top: 2px solid $color--teal;
    font-weight: normal;
    text-align: center;
  }

  &__menu-link {
    @extend .link;

    font-weight: 700;
  }
}
