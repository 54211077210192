.icon-message {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__text {
    background-color: transparent;
    font-family: $font--museo;
    font-weight: bold;
    font-size: $text--1;
    color: $color--purple;
  }
}

.icon-message svg {
  @extend .mr-2;
  flex-shrink: 0;
}
