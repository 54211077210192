.link {
  display: inline;
  text-align: left;

  /* remove background from button.link */
  background: none;

  &--call-to-action {
    color: $color--blue;
    font-size: $text--1;
    font-family: $font--museo;
    font-weight: 500;
    line-height: 2;

    @add-mixin media m {
      display: flex;
      align-items: center;
      overflow: hidden;

      .link__text-wrapper {
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &::after {
      @extend .ml-2;
      display: inline-block;
      content: '';
      height: 20px;
      width: 20px;
      background-image: url('images/arrow.svg');
      background-size: cover;
      background-repeat: no-repeat;
      vertical-align: middle;
      flex-shrink: 0;
    }
  }

  &--blue-light {
    color: $color--blue-light;

    &::after {
      background-image: url('images/arrow-blue-light.svg');
    }
  }

  &--call-to-action .a {
    text-decoration: none;
  }

  &--join-ncvo {
    display: inline-block;
    border-radius: $border-radius--2;
    padding: $space--3 $space--4;
    line-height: 1.375;
    transition: background-color $transition--default;
    background-color: #fff;
    color: $color--purple;
    font-size: $text--4;
    font-weight: 900;

    &:hover {
      background-color: $color--teal;
      text-decoration: none;
    }

    &::after {
      @extend .ml-2;
      display: inline-block;
      content: '';
      height: 1.5em;
      width: 1.5em;
      background-image: url('images/arrow.svg');
      background-size: cover;
      background-repeat: no-repeat;
      vertical-align: middle;
      flex-shrink: 0;
    }
  }

  &--booking {
    @extend .link--join-ncvo;
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 318px;
    align-items: center;
    z-index: 1;
    &::after {
      margin-left: auto;
    }

    &:hover {
      background-color: $color--purple;
      color: $color--white;
      &::after {
        background-image: url('images/arrow-white.svg');
      }
    }

    &__label {
      font-size: $text--3;
    }

    &__date {
      font-size: $text--2;
      font-weight: $font-weight--3;
    }

    &--teal {
      background-color: $color--teal-60;
    }

    &--orange {
      background-color: $color--orange-light;
    }
  }

  .events & {
    &--join-ncvo {
      background-color: $color--orange;
      width: fit-content;

      &:hover {
        background-color: $color--white;
      }
    }
  }

  .training & {
    &--join-ncvo {
      background-color: $color--teal;
      width: fit-content;

      &:hover {
        background-color: $color--white;
      }
    }
  }
}
