@import url('https://use.fontawesome.com/releases/v5.6.3/css/all.css');

.fa,
.fab,
.fal,
.far,
.fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  text-align: center;
}

.fa-info-circle {
  font-size: 1.4rem;
  color: $color--blue;
  vertical-align: middle;
}

.fa-info-circle:hover {
  color: #045387;
  cursor: pointer;
}

@media print {
  .fa-info-circle {
    display: none;
  }
}
