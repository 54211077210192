.stack {
  display: flex;
  flex-direction: column;

  @each $space $i in $spaces {
    &--space-$(i) > * + *:not([class*='mt-']) {
      margin-top: $space;
    }

    @each $breakpoint in $breakpoints {
      &--space-$(breakpoint)-$(i) > * + * {
        @add-mixin media $breakpoint {
          margin-top: $space;
        }
      }
    }
  }

  > .heading + * {
    margin-top: $space--2;
  }

  &--horizontal {
    flex-direction: row;

    @each $space $i in $spaces {
      &.stack--space-$(i) > * + * {
        margin-left: $space;
        margin-top: 0;
      }

      @each $breakpoint in $breakpoints {
        &.stack--space-$(breakpoint)-$(i) > * + * {
          @add-mixin media $breakpoint {
            margin-left: $space;
            margin-top: 0;
          }
        }
      }
    }

    &.stack--center-x {
      @extend .stack--center-y;
    }
    &.stack--center-y {
      @extend .stack--center-x;
    }
  }

  &--center {
    justify-content: center;
    align-items: center;
  }

  &--center-x {
    align-items: center;
    justify-content: stretch;
  }
  &--center-y {
    justify-content: center;
    align-items: stretch;
  }

  &--space-between {
    justify-content: space-between;
  }

  &--responsive {
    flex-direction: column;
    @add-mixin media s {
      flex-direction: row;
    }
  }

  // Put a line on screen inbetween the space of the items next to each other
  &--line {
    > * + * {
      width: 100%;
      position: relative;

      .stack--horizontal& {
        width: auto;
        height: 100%;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        background: #d4d5cf; // TODO: PUT IN COLOURS?
      }
    }

    @each $space $i in $spaces {
      &.stack--space-$(i) > * + *:before {
        width: 80%;
        height: 2px;
        top: calc($space / -2); // half the space
        left: 10%;
        transform: translateY(50%);
      }

      .stack--horizontal&.stack--space-$(i) > * + *:before {
        width: 2px;
        height: 80%;
        top: 10%;
        left: calc($space / -2); // half the space
        transform: translateX(50%);
      }

      @each $breakpoint in $breakpoints {
        &--space-$(breakpoint)-$(i) > * + *:before {
          @add-mixin media $breakpoint {
            top: calc($space / -2); // half the space
          }
        }
        &.stack--horizontal.stack--space-$(breakpoint)-$(i) > * + *:before {
          @add-mixin media $breakpoint {
            left: calc($space / -2); // half the space
          }
        }
      }
    }
  }
}
