.text-input {
  display: block;
  background-color: transparent;
  font-family: $font--museo;
  font-weight: bold;
  font-size: $text--3;
  color: $color--purple;

  &__input {
    @extend .my-1, .py-2, .px-4;
    display: block;
    border: 2px solid $color--grey;
    border-radius: $border-radius--2;
    background-color: #fff;
    width: 100%;
    font-family: $font--museo;
    font-weight: normal;
    color: $color--text-grey;
    font-size: $text--2;
  }

  &__required::after {
    content: ' *';
    color: #d03c38;
  }

  &--error {
    .text-input__input {
      border-color: #d03c38;
    }
  }

  &__input:focus,
  &__input:focus-visible {
    background-color: #fff;
    border: 2px solid $color--teal;
    outline: none;
  }

  &__hint {
    color: $color--text-grey;
    font-size: $text--1;
    font-weight: normal;
  }

  &__error-hint {
    @extend .text-input__hint;

    color: #d03c38; // TODO: get an error red from the guides
  }
}
